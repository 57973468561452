import {
  IAffiliationFormDataModel,
  IAffiliationTrackingDataModel,
} from "@helpers/interfaces/affiliation-tracking";

export function parseAffiliationTrackingData(
  rows: Array<IAffiliationTrackingDataModel>
) {
  const _rows = [];

  for (let row of rows) {
    const item = {
      ...row,
      isPrimaryLabel: row.isPrimary === true ? "Yes" : "No",
      isActiveLabel: row.isActive === true ? "Active" : "Inactive",
    };
    _rows.push(item);
  }

  return _rows;
}

export interface IAffiliationTrackingFormErrors {
  providerNpi: string;
  providerDob: string;
  providerLastName: string;
  requestorName: string;
  requestorOrganizationName: string;
  requestorEmailAddress: string;
  requestorAddress: string;
  requestorState: string;
  requestorCity: string;
  requestorZip: string;
}

export const validateAffiliationTrackingForm = (
  values: IAffiliationFormDataModel
) => {
  const errors = {} as IAffiliationTrackingFormErrors;

  if (!values.providerNpi) {
    errors.providerNpi = "Required";
  }

  if (!values.providerDob) {
    errors.providerDob = "Required";
  }

  if (!values.providerLastName) {
    errors.providerLastName = "Required";
  }

  if (!values.requestorName) {
    errors.requestorName = "Required";
  }

  if (!values.requestorOrganizationName) {
    errors.requestorOrganizationName = "Required";
  }

  if (!values.requestorEmailAddress) {
    errors.requestorEmailAddress = "Required";
  }

  if (!values.requestorAddress) {
    errors.requestorAddress = "Required";
  }

  if (!values.requestorState) {
    errors.requestorState = "Required";
  }

  if (!values.requestorCity) {
    errors.requestorCity = "Required";
  }

  if (!values.requestorZip) {
    errors.requestorZip = "Required";
  }

  return errors;
};
