import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAffiliationTrackingList } from "@services/affiliation-tracking";
import { useLocation } from "react-router-dom";
import AveraLetterLogo from "@images/AveraLetterLogo.png";
import { useAppSelector } from "@hooks/index";
import moment from "moment";
import { IAffiliationFormDataModel } from "@helpers/interfaces/affiliation-tracking";
import hospitalData from "@constants/json-data/table-columns/affiliation-tracking/affiliation-hospital-data";

const AffiliationTrackingLetter = () => {
  const dispatch = useDispatch<any>();
  const { state } = useLocation();
  const formData: IAffiliationFormDataModel = state?.formData!;

  const affiliationList = useAppSelector(
    (state) => state.affiliationTracking.affiliationTrackingList
  );
  const cities = useAppSelector((state) => state.master.cities);

  const loadAffiliationLetterData = useCallback(async () => {
    const payload = {
      ...formData,
      pageLimit: affiliationList.paging.totalItems,
    };
    dispatch(getAffiliationTrackingList(payload));
  }, [dispatch, formData, affiliationList.paging.totalItems]);

  useEffect(() => {
    loadAffiliationLetterData();
  }, [loadAffiliationLetterData]);

  const providerName =
    affiliationList?.page && affiliationList.page.length > 0
      ? affiliationList.page[0].providerName
      : "--NA--";

  const getRequestorCity = () => {
    const cityName = cities.find(
      (city) => city.id === formData.requestorCity
    )?.name;
    return cityName ? cityName : "--NA--";
  };

  const handleDownloadClick = () => {
    // Get the HTML content of the page
    const pageContent = document.getElementById(
      "affiliationLetterMain"
    )?.outerHTML;

    if (pageContent) {
      // Create a hidden iframe
      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.top = "-10000px"; // Hide the iframe offscreen
      document.body.appendChild(iframe);

      // Write the content to the iframe
      const iframeDoc =
        iframe.contentDocument || iframe.contentWindow?.document;
      if (iframeDoc) {
        iframeDoc.open();
        iframeDoc.write(pageContent);
        iframeDoc.close();

        // Trigger print
        iframe.contentWindow?.focus(); // Focus on the iframe for print dialog
        iframe.contentWindow?.print();

        // Remove the iframe after printing
        setTimeout(() => {
          document.body.removeChild(iframe);
        }, 1000);
      }
    }
  };

  const downloadControlAttributes = {
    className: "btn btnorg m-4 float-end",
    onClick: handleDownloadClick,
  };

  return (
    <div>
      <div
        id="affiliationLetterMain"
        style={{
          padding: "54px",
          display: "flex",
          flexDirection: "column",
          width: "1000px",
          justifyContent: "normal",
          margin: "0 auto",
          border: "1px solid black",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        <header style={{ textAlign: "center", marginBottom: "20px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img
              src={AveraLetterLogo}
              alt="AveraLetterLogo"
              style={{ width: "200px", height: "100px" }}
            />
            <div>
              <div
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  textDecoration: "uppercase",
                }}
              >
                Confidential
              </div>
              <address
                style={{
                  marginTop: "54px",
                  fontStyle: "normal",
                  marginBottom: "10px",
                  color: "#555",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    width: "150px",
                    lineHeight: "1.6",
                    margin: "10px 0",
                  }}
                >
                  {`${
                    formData.requestorAddress || "--NA--"
                  }, ${getRequestorCity()} ${
                    formData.requestorZip || "--NA--"
                  }`}
                </p>
              </address>
            </div>
          </div>

          <h1
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Credentials Verification
          </h1>
        </header>

        <section>
          <p
            style={{
              fontSize: "14px",
              color: "#ff6666",
              lineHeight: "1.6",
              margin: "10px 0",
            }}
          >
            {moment().format("MMMM D, YYYY")}
          </p>
          <div style={{ color: "#ff6666" }}>
            <div>
              <strong>{providerName}</strong>
            </div>
            <div>{formData.requestorOrganizationName}</div>
            <div style={{ width: "190px" }}>
              {`${
                formData.requestorAddress || "--NA--"
              }, ${getRequestorCity()} ${formData.requestorZip || "--NA--"}`}
            </div>
          </div>
          <p style={{ lineHeight: "1.6", margin: "10px 0" }}>
            <p style={{ lineHeight: "1.6", margin: "10px 0" }}>
              <strong>Re:</strong>{" "}
              <span style={{ color: "#ff6666" }}>{providerName}</span>
            </p>
            <p style={{ lineHeight: "1.6", margin: "10px 0" }}>
              <strong>DOB:</strong>{" "}
              <span style={{ color: "#ff6666" }}>
                {formData.providerDob
                  ? moment(formData.providerDob).format("MM/DD/YYYY")
                  : "--NA--"}
              </span>
            </p>
          </p>
          <p style={{ lineHeight: "1.6", margin: "10px 0" }}>
            Due to the volume of inquiries received, please accept this letter
            in response to your request for verification of clinical privileges
            and Medical Staff appointment for{" "}
            <span style={{ color: "#ff6666" }}>{providerName}.</span>
          </p>
        </section>

        <section>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              margin: "20px 0",
            }}
          >
            {affiliationList?.page && affiliationList.page.length > 0 ? (
              <>
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                        color: "#555",
                        backgroundColor: "#f4f4f4",
                      }}
                    >
                      Hospital/Facility
                    </th>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                        color: "#555",
                        backgroundColor: "#f4f4f4",
                      }}
                    >
                      Specialty
                    </th>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                        color: "#555",
                        backgroundColor: "#f4f4f4",
                      }}
                    >
                      Category
                    </th>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                        color: "#555",
                        backgroundColor: "#f4f4f4",
                      }}
                    >
                      Status
                    </th>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                        color: "#555",
                        backgroundColor: "#f4f4f4",
                      }}
                    >
                      Original Appt. Date
                    </th>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                        color: "#555",
                        backgroundColor: "#f4f4f4",
                      }}
                    >
                      Termination Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {affiliationList.page.map((affiliation) => {
                    const originalApptDate = affiliation.afflicationStartDate
                      ? moment(affiliation.afflicationStartDate).format(
                          "MM/DD/YYYY"
                        )
                      : "--NA--";
                    const terminationDate = affiliation.afflicationEndDate
                      ? moment(affiliation.afflicationEndDate).format(
                          "MM/DD/YYYY"
                        )
                      : "--NA--";

                    return (
                      <tr>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "left",
                            color: "#ff6666",
                          }}
                        >
                          {affiliation.hospitalName}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "left",
                            color: "#ff6666",
                          }}
                        >
                          {affiliation.hospitalName}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "left",
                            color: "#ff6666",
                          }}
                        >
                          {affiliation.hospitalName}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "left",
                            color: "#ff6666",
                          }}
                        >
                          {affiliation.privilegeStatus}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "left",
                            color: "#ff6666",
                          }}
                        >
                          {originalApptDate}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "left",
                            color: "#ff6666",
                          }}
                        >
                          {terminationDate}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            ) : (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "25px",
                }}
              >
                No data Found
              </div>
            )}
          </table>
        </section>
        <section>
          <p style={{ lineHeight: "1.6", margin: "10px 0" }}>
            There is nothing in his/her credentialing file relating to any
            concerns regarding his/her practice or professional conduct. He/She
            is in good standing and has complied with all requirements of the
            Medical Staff Bylaws, Rules and Regulations and hospital policies.
          </p>
        </section>
        <section>
          {hospitalData.map((hospital) => {
            return (
              <p style={{ lineHeight: "1.6", margin: "10px 0" }}>
                <span style={{ fontWeight: "bold" }}>{hospital.name}</span>,{" "}
                <span>
                  {hospital.address}, Phone: {hospital.phone} Fax:{" "}
                  {hospital.fax}
                </span>
              </p>
            );
          })}
        </section>
        <section>
          <p style={{ fontSize: "10px", lineHeight: "1.6", margin: "10px 0" }}>
            *This facility is a Joint Commission accredited Hospital, and as
            such, has a process in place for Ongoing Professional Practice
            Evaluation (OPPE). During this evaluation physicians are reviewed
            for various aspects of the quality of care that they deliver. These
            reviews include metrics in the six competencies of the Joint
            Commission Standards: patient care, medical/clinical knowledge,
            practice-based learning and improvement, interpersonal and
            communication skills, professionalism and systems- based practice.
          </p>
        </section>
      </div>
      <button {...downloadControlAttributes}>Download</button>
    </div>
  );
};

export default AffiliationTrackingLetter;
