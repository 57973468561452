import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { ISecureInputFieldProps } from "helpers/uiInterfaces/common";

import styles from "./SecureInputField.module.scss";

const SecureInputField: React.FC<ISecureInputFieldProps> = (props) => {
  const { name, error, value, disabled, onChange, onBlur, onFocus } = props;

  const [show, setShow] = useState(false);

  let inputClassName = styles.inputControl;

  if (error) {
    inputClassName += ` ${styles.error}`;
  }

  const inputControlAttributes = {
    type: show ? "text" : "password",
    className: inputClassName,
    name,
    value,
    disabled: disabled ? disabled : false,
    onChange,
    onBlur,
    onFocus,
  };

  const showHideControlAttributes = {
    className: styles.showHideControl,
    onClick() {
      setShow(!show);
    },
  };

  return (
    <div className={styles.secureInputFieldMain}>
      <input {...inputControlAttributes} />
      <button {...showHideControlAttributes}>
        {show ? (
          <FontAwesomeIcon icon={faEye} />
        ) : (
          <FontAwesomeIcon icon={faEyeSlash} />
        )}
      </button>
    </div>
  );
};

export default SecureInputField;
