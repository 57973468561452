const pageTitles = {
  default: "Credentialing Affiliation Privilege Tracker",
  dashboard: "Dashboard | Credentialing Affiliation Privilege Tracker",
  credentialingTracking: "Credentialing Tracking",
  affiliationTracking: "Affiliation Tracking",
  affiliationTrackingLetter: "Affiliation Tracking Letter",
  privilegeViewer: "Privilege Viewer",
};

export default pageTitles;
