import toastTypes from "@constants/toast-types";
import axiosClient from "@features/axiosClient";
import { mastersActions } from "@features/master";
import { toastMessageActions } from "@features/ui/ToastMessage";
import { handleServerInteractionError } from "@utilities/services";

export const getPropertiesByPropertyType =
  (searchText: string) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          `api/Master/GetPropertiesListByPropertyType?PropertyType=${searchText}`
        );

        const data = response.data;

        if (response.status === 200) {
          dispatch(mastersActions.updateLevelOfExperience(data));
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: "",
              type: toastTypes.DANGER,
            })
          );
        }

        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

export const getAllStateByCountryId =
  (countryId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          `api/AveraTrackers/GetAllState?CountryId=${countryId}`
        );

        const data = response.data;

        if (response.status === 200) {
          dispatch(mastersActions.updateStates(data));
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: "",
              type: toastTypes.DANGER,
            })
          );
        }

        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

export const getCitiesByStateId =
  (stateId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          `api/AveraTrackers/GetAllCityByStateId?StateId=${stateId}`
        );

        const data = response.data;

        if (response.status === 200) {
          dispatch(mastersActions.updateCities(data));
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: "",
              type: toastTypes.DANGER,
            })
          );
        }

        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

export const getOrganizationsByNetworkId =
  (networkId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          `api/AveraTrackers/GetOrganizationsByNetworkId?NetworkId=${networkId}`
        );

        const data = response.data;

        if (response.status === 200) {
          dispatch(mastersActions.updateOrganizations(data));
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: "",
              type: toastTypes.DANGER,
            })
          );
        }

        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };
