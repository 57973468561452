import toastTypes from "@constants/toast-types";
import axiosClient from "@features/axiosClient";
import { privilegeActions } from "@features/privilege-viewer";
import { toastMessageActions } from "@features/ui/ToastMessage";
import { handleServerInteractionError } from "@utilities/services";

function showLoading(dispatch: any) {
  dispatch(privilegeActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(privilegeActions.updateLoading(false));
}

export const getPrivilegeList = (payload: any) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const {
      organizationId,
      providerLastName,
      providerDob,
      pageIndex,
      pageSize,
    } = payload;
    showLoading(dispatch);
    try {
      const response = await axiosClient.get(
        `api/AveraTrackers/GetPrivilegeViewerInfo?OrganizationId=${organizationId}&ProviderLastName=${providerLastName}&ProviderDob=${providerDob}&PageIndex=${pageIndex}&PageSize=${pageSize}`
      );
      const data = response.data;

      if (response.status === 200) {
        dispatch(privilegeActions.updatePrivilegeList(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: "",
            type: toastTypes.DANGER,
          })
        );
      }
      hideLoading(dispatch);
      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });
};
