import ContentHeader from "@common/ContentHeader";
import { useAppSelector } from "@hooks/index";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./AffiliationTracking.module.scss";
import FormInputTextControl from "@common/form-controls/FormInputTextControl";
import FormDateInputControl from "@common/form-controls/FormDateInputControl";
import { getAffiliationTrackingList } from "@services/affiliation-tracking";
import affiliationTrackingListColumns from "@constants/json-data/table-columns/affiliation-tracking/affiliationTrackingListColumns";
import { IAffiliationFormDataModel } from "@helpers/interfaces/affiliation-tracking";
import PaginatedTable from "@common/paginated-table/PaginatedTable";
import NoContentSection from "@common/no-content-section/NoContentSection";
import Spinner from "@common/spinner/Spinner";
import {
  parseAffiliationTrackingData,
  validateAffiliationTrackingForm,
} from "./utilities";
import { setDefaultAffiliationTrackingFormData } from "@helpers/configMiddleware/affiliation-tracking";
import { useFormik } from "formik";
import { affiliationtrackingActions } from "@features/affiliation-tracking";
import FormSelectInputControl from "@common/form-controls/FormSelectInputControl";
import { parseSelectControlsData } from "@utilities/index";
import {
  getAllStateByCountryId,
  getCitiesByStateId,
} from "@services/master/master.services";
import routesNames from "@constants/route-names";
import { useNavigate } from "react-router-dom";

const AffiliationTracking = () => {
  const [formData, setFormData] = useState<IAffiliationFormDataModel>(
    setDefaultAffiliationTrackingFormData()
  );
  const [downloadLetter, setDownloadLetter] = useState(false);
  const [disableDownloadBtn, setDisableDownloadBtn] = useState(true);

  const formik = useFormik({
    initialValues: formData as IAffiliationFormDataModel,
    validate: validateAffiliationTrackingForm,
    onSubmit: handleSaveControlClick,
  });
  const formikValues = formik.values;
  const formikErrors = formik.errors;

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const affiliationTrackingState = useAppSelector(
    (state) => state.affiliationTracking
  );
  const states = useAppSelector((state) => state.master.states);
  const cities = useAppSelector((state) => state.master.cities);

  const affiliationTrackingList =
    affiliationTrackingState.affiliationTrackingList?.page || [];

  const loadDependencies = useCallback(async () => {
    await dispatch(getAllStateByCountryId(233));
  }, [dispatch]);

  useEffect(() => {
    loadDependencies();
  }, [loadDependencies]);

  const loadCitiesByStateId = useCallback(async () => {
    if (formData.requestorState)
      await dispatch(getCitiesByStateId(formData.requestorState));
  }, [dispatch, formData.requestorState]);

  useEffect(() => {
    loadCitiesByStateId();
  }, [loadCitiesByStateId, formData.requestorState]);

  useEffect(() => {
    return () => {
      dispatch(affiliationtrackingActions.resetState());
    };
  }, [dispatch]);

  const contentHeaderAttributes = {
    title: "Affiliation Tracking",
    instructions: [],
  };

  async function loadAffiliationTrackingList(
    page: number,
    limit: number,
    isSubmit: boolean
  ) {
    const payload = { ...formData, pageIndex: page, pageSize: limit, isSubmit };
    const res = await dispatch(getAffiliationTrackingList(payload));
    if (res.status === 200) {
      setDisableDownloadBtn(false);
      setFormData({
        ...formData,
        pageIndex: page,
        pageSize: limit,
      });
    }
  }

  const handleControlChange = (name: string, value: string) => {
    const modifiedFormData = { ...formData, [name]: value };
    formik.setFieldValue(name, value);
    setFormData(modifiedFormData);
  };

  async function handleSaveControlClick() {
    if (downloadLetter) {
      navigate(`/${routesNames.affiliationTrackingLetter}`, {
        state: { formData },
      });
    } else {
      loadAffiliationTrackingList(formData.pageIndex, formData.pageSize, true);
    }
  }

  const renderFields = () => {
    let npiError = "" as string | undefined;

    if (
      formikErrors.providerNpi !== "" &&
      formik.touched.providerNpi === true
    ) {
      npiError = formikErrors.providerNpi;
    }

    let dobError = "" as string | undefined;

    if (
      formikErrors.providerDob !== "" &&
      formik.touched.providerDob === true
    ) {
      dobError = formikErrors.providerDob;
    }

    let lastNameError = "" as string | undefined;

    if (
      formikErrors.providerLastName !== "" &&
      formik.touched.providerLastName === true
    ) {
      lastNameError = formikErrors.providerLastName;
    }

    let requestorNameError = "" as string | undefined;

    if (
      formikErrors.requestorName !== "" &&
      formik.touched.requestorName === true
    ) {
      requestorNameError = formikErrors.requestorName;
    }

    let requestorOrgError = "" as string | undefined;

    if (
      formikErrors.requestorOrganizationName !== "" &&
      formik.touched.requestorOrganizationName === true
    ) {
      requestorOrgError = formikErrors.requestorOrganizationName;
    }

    let requestorEmailError = "" as string | undefined;

    if (
      formikErrors.requestorEmailAddress !== "" &&
      formik.touched.requestorEmailAddress === true
    ) {
      requestorEmailError = formikErrors.requestorEmailAddress;
    }

    let requestorAddressError = "" as string | undefined;

    if (
      formikErrors.requestorAddress !== "" &&
      formik.touched.requestorAddress === true
    ) {
      requestorAddressError = formikErrors.requestorAddress;
    }

    let requestorStateError = "" as string | undefined;

    if (
      formikErrors.requestorState !== "" &&
      formik.touched.requestorState === true
    ) {
      requestorStateError = formikErrors.requestorState;
    }

    let requestorCityError = "" as string | undefined;

    if (
      formikErrors.requestorCity !== "" &&
      formik.touched.requestorCity === true
    ) {
      requestorCityError = formikErrors.requestorCity;
    }

    let requestorZipError = "" as string | undefined;

    if (
      formikErrors.requestorZip !== "" &&
      formik.touched.requestorZip === true
    ) {
      requestorZipError = formikErrors.requestorZip;
    }

    const npiControlAttributes = {
      label: "Provider NPI",
      placeholder: "Enter Provider NPI",
      type: "text",
      name: "providerNpi",
      required: true,
      error: npiError,
      value: formikValues.providerNpi,
      onChange(e: any) {
        handleControlChange("providerNpi", e.target.value);
      },
      onBlur: formik.handleBlur,
    };

    const dobControlAttributes = {
      label: "Provider DOB",
      name: "providerDob",
      required: true,
      error: dobError,
      value: formikValues.providerDob,
      onChange(value: string) {
        handleControlChange("providerDob", value);
      },
      onBlur: formik.handleBlur,
    };

    const lastNameControlAttributes = {
      label: "Provider Last Name",
      placeholder: "Enter Last Name",
      type: "text",
      name: "providerLastName",
      required: true,
      error: lastNameError,
      value: formikValues.providerLastName,
      onChange(e: any) {
        handleControlChange("providerLastName", e.target.value);
      },
      onBlur: formik.handleBlur,
    };

    const requestorNameControlAttributes = {
      label: "Requestor Name",
      placeholder: "Enter Requestor Name",
      type: "text",
      name: "requestorName",
      required: true,
      error: requestorNameError,
      value: formikValues.requestorName,
      onChange(e: any) {
        handleControlChange("requestorName", e.target.value);
      },
      onBlur: formik.handleBlur,
    };

    const requestorOrganizationNameControlAttributes = {
      label: "Requestor Organization Name",
      placeholder: "Enter Requestor Organization",
      type: "text",
      name: "requestorOrganizationName",
      required: true,
      error: requestorOrgError,
      value: formikValues.requestorOrganizationName,
      onChange(e: any) {
        handleControlChange("requestorOrganizationName", e.target.value);
      },
      onBlur: formik.handleBlur,
    };

    const requestorEmailAddressControlAttributes = {
      label: "Requestor Email Address",
      placeholder: "Enter Requestor Email",
      type: "text",
      name: "requestorEmailAddress",
      required: true,
      error: requestorEmailError,
      value: formikValues.requestorEmailAddress,
      onChange(e: any) {
        handleControlChange("requestorEmailAddress", e.target.value);
      },
      onBlur: formik.handleBlur,
    };

    const requestorAddressControlAttributes = {
      label: "Requestor Address",
      placeholder: "Enter Requestor Address",
      type: "text",
      name: "requestorAddress",
      required: true,
      error: requestorAddressError,
      value: formikValues.requestorAddress,
      onChange(e: any) {
        handleControlChange("requestorAddress", e.target.value);
      },
      onBlur: formik.handleBlur,
    };

    const requestorStateControlAttributes = {
      label: "Requestor State",
      name: "requestorState",
      value: formikValues.requestorState,
      required: true,
      error: requestorStateError,
      data: parseSelectControlsData(states),
      onChange: handleControlChange,
      onBlur: formik.handleBlur,
    };

    const requestorCityControlAttributes = {
      label: "Requestor City",
      name: "requestorCity",
      value: formikValues.requestorCity,
      required: true,
      error: requestorCityError,
      data: parseSelectControlsData(cities),
      onChange: handleControlChange,
      onBlur: formik.handleBlur,
    };

    const requestorZipControlAttributes = {
      label: "Requestor Zip",
      placeholder: "Enter Requestor Zip",
      type: "text",
      name: "requestorZip",
      value: formikValues.requestorZip,
      required: true,
      error: requestorZipError,
      onChange(e: any) {
        handleControlChange("requestorZip", e.target.value);
      },
      onBlur: formik.handleBlur,
    };

    const submitControlAttributes = {
      className: "btn btnorg mt-4",
      onClick() {
        formik.handleSubmit();
      },
    };

    const downloadControlAttributes = {
      className: "btn btnorg mt-4",
      disabled: disableDownloadBtn,
      onClick() {
        setDownloadLetter(true);
        setDisableDownloadBtn(true);
        formik.handleSubmit();
      },
    };

    return (
      <div className={styles.card}>
        <div className="row align-items-center">
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormInputTextControl {...npiControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormDateInputControl {...dobControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormInputTextControl {...lastNameControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormInputTextControl {...requestorNameControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormInputTextControl
              {...requestorOrganizationNameControlAttributes}
            />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormInputTextControl {...requestorEmailAddressControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormInputTextControl {...requestorAddressControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormSelectInputControl {...requestorStateControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormSelectInputControl {...requestorCityControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormInputTextControl {...requestorZipControlAttributes} />
          </div>
          <div className="col-md-4 col-sm-12 col-xs-12 mb-3 d-flex gap-2">
            <button {...submitControlAttributes}>Find Affiliations</button>
            <button {...downloadControlAttributes}>
              Download Affiliation Letter
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    if (!affiliationTrackingList || affiliationTrackingState.loading) {
      return <Spinner />;
    }

    if (affiliationTrackingList.length === 0) {
      return <NoContentSection />;
    }

    const affiliationTrackingDataList = parseAffiliationTrackingData(
      affiliationTrackingList
    );

    const paginatedTableAttributes = {
      columns: affiliationTrackingListColumns,
      rows: affiliationTrackingDataList,
      currentPage: formData.pageIndex,
      totalRecord:
        affiliationTrackingState.affiliationTrackingList?.paging?.totalItems,
      rowsPerPage: formData.pageSize,
      onPageLimitChange(limit: number) {
        loadAffiliationTrackingList(formData.pageIndex, limit, false);
      },
      onPageChange(page: number) {
        loadAffiliationTrackingList(page, formData.pageSize, false);
      },
    };

    return <PaginatedTable {...paginatedTableAttributes} />;
  };

  return (
    <div id={styles.affiliationTrackingMain}>
      <ContentHeader {...contentHeaderAttributes} />
      <div className="container">
        <h4 className={styles.gridTitle}>Affiliation Tracking List</h4>
        {renderFields()}
        {renderTable()}
      </div>
    </div>
  );
};

export default AffiliationTracking;
