import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IMastersDataModel,
  IOrganizationOptionsDataModel,
} from "@helpers/interfaces/master/index";

const initialState = {
  propertyListByPropertyType: [] as Array<IMastersDataModel>,
  states: [] as Array<IMastersDataModel>,
  cities: [] as Array<IMastersDataModel>,
  organizations: {} as IOrganizationOptionsDataModel,
};

const mastersSlice = createSlice({
  name: "masters",
  initialState,
  reducers: {
    updateLevelOfExperience: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.propertyListByPropertyType = action.payload;
    },

    updateStates: (state, action: PayloadAction<Array<IMastersDataModel>>) => {
      state.states = action.payload;
    },

    updateCities: (state, action: PayloadAction<Array<IMastersDataModel>>) => {
      state.cities = action.payload;
    },

    updateOrganizations: (
      state,
      action: PayloadAction<IOrganizationOptionsDataModel>
    ) => {
      state.organizations = action.payload;
    },
  },
});

export const mastersActions = mastersSlice.actions;
export default mastersSlice.reducer;
