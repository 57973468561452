const hospitalData = [
  {
    name: "Avera Creighton Hospital",
    address: "1503 Main Street, Creighton, NE 68729",
    phone: "402.358.5700",
    fax: "402.358.5948",
  },
  {
    name: "Avera Dells Area Hospital",
    address: "909 N. Iowa Street, Dell Rapids, SD 57022",
    phone: "605.428.5431",
    fax: "605.428.2452",
  },
  {
    name: "Avera DeSmet Memorial Hospital",
    address: "PO Box 160, 306 Prairie Ave SW, DeSmet, SD 57231-0160",
    phone: "605.854.3329",
    fax: "605.854.3161",
  },
  {
    name: "Avera Flandreau Medical Center",
    address: "214 N Prairie Street, Flandreau, SD 57438",
    phone: "605.997.2433",
    fax: "605.997.2380",
  },
  {
    name: "Avera Gregory Healthcare Center",
    address: "110 S Logan Ave, PO Box 408, Gregory, SD 57533",
    phone: "605.835.8394",
    fax: "605.835.9422",
  },
  {
    name: "Avera Hand County Memorial Hospital",
    address: "300 W. 5th Street, Miller, SD 57362",
    phone: "605.853.0158",
    fax: "605.853.0333",
  },
  {
    name: "Avera Heart Hospital of South Dakota",
    address: "4500 W. 69th Street, Sioux Falls, SD 57108",
    phone: "605.977.7000",
    fax: "605.977.7001",
  },
  {
    name: "Avera Holy Family Hospital",
    address: "826 N. 8th Street, Estherville, IA 51334",
    phone: "712.362.2631",
    fax: "712.362.6151",
  },
  {
    name: "Avera Marshall Regional Medical Center",
    address: "300 S. Bruce Street, Marshall, MN 56258",
    phone: "507.537.9165",
    fax: "507.537.9053",
  },
  {
    name: "Avera McKennan Hospital & University Health Center",
    address: "1325 S. Cliff Avenue, Sioux Falls, SD 57105",
    phone: "605.322.7976",
    fax: "605.977.7095",
  },
  {
    name: "Avera Merrill Pioneer Hospital",
    address: "1100 S. 10th Avenue, Rock Rapids, IA 51246",
    phone: "712.472.5400",
    fax: "712.472.5501",
  },
  {
    name: "Avera Missouri River",
    address: "n/a",
    phone: "n/a",
    fax: "n/a",
  },
  {
    name: "Avera Queen of Peace Hospital",
    address: "525 N Foster, Mitchell, SD 57301",
    phone: "605.995.2000",
    fax: "605.995.2441",
  },
  {
    name: "Avera Sacred Heart Hospital",
    address: "501 Summit, Yankton, SD 57078",
    phone: "605.668.8000",
    fax: "605.668.8369",
  },
  {
    name: "Avera St. Anthony's Hospital",
    address: "300 N. 2nd Street, O'Neill, NE 68763",
    phone: "402.336.2611",
    fax: "402.336.5135",
  },
  {
    name: "Avera St. Benedict Health Center",
    address: "401 W. Glynn Drive, Parkston, SD 57366",
    phone: "605.928.3311",
    fax: "605.928.7368",
  },
  {
    name: "Avera St. Luke's Hospital",
    address: "305 S. State Street, Aberdeen, SD 57401",
    phone: "605.622.5549",
    fax: "605.622.2804",
  },
  {
    name: "Avera St. Mary's Hospital",
    address: "801 E. Sioux Avenue, Pierre, SD 57501",
    phone: "605.224.3100",
    fax: "605.224.3439",
  },
  {
    name: "Avera Tyler Hospital",
    address: "240 Willow Street, Tyler, MN 56178",
    phone: "507.247.5521",
    fax: "507.247.2325",
  },
  {
    name: "Avera Weskota Medical",
    address: "n/a",
    phone: "n/a",
    fax: "n/a",
  },
  {
    name: "Hegg Memorial Hospital/ Avera Health",
    address: "1202 21st Avenue, Rock Valley, IA 51247",
    phone: "712.476.8000",
    fax: "712.476.8064",
  },
  {
    name: "Lakes Regional Health Care",
    address: "2301 Highway 71 South, PO Box AB, Spirit Lake, IA 51360",
    phone: "712.336.1230",
    fax: "712.336.8620",
  },
  {
    name: "Landmann-Jungman Memorial Hospital",
    address: "600 Billars Street, PO Box 307, Scotland, SD 57059",
    phone: "605.583.2226",
    fax: "605.583.4557",
  },
  {
    name: "Milbank Area Hospital/Avera Health",
    address: "301 Flynn Drive, Milbank, SD 57252",
    phone: "605.432.4538",
    fax: "605.432.5412",
  },
  {
    name: "Osceola Regional Health Center",
    address: "600 9th Avenue N, PO Box 258, Sibley, IA 51249",
    phone: "712.754.2574",
    fax: "712.754.3782",
  },
  {
    name: "Pipestone County Medical Center & Hospital",
    address: "916 4th Avenue SW, Pipestone, MN 56164",
    phone: "507.825.5811",
    fax: "507.825.4754",
  },
  {
    name: "Platte Health Center/Avera Health",
    address: "601 E. 7th Street, PO Box 200, Platte, SD 57369",
    phone: "605.337.3364",
    fax: "605.337.2670",
  },
  {
    name: "St. Michael's Hospital",
    address: "410 W. 16th Avenue, Tyndall, SD 57066",
    phone: "605.589.4602",
    fax: "605.589.3288",
  },
  {
    name: "Wagner Community Memorial Hospital",
    address: "513 3rd Street SW, PO Box 280, Wagner, SD 57380",
    phone: "605.384.3611",
    fax: "605.384.7303",
  },
];

export default hospitalData;
