import pageTitles from "@constants/page-titles";
import routesNames from "@constants/route-names";

export function getPageTitleByLocation(location: any) {
  const pathName = location.pathname;

  switch (pathName) {
    case routesNames.dashboardRoute:
      return pageTitles.dashboard;
    case routesNames.credentialingTrackingRoute:
      return pageTitles.credentialingTracking;
    case routesNames.affiliationTrackingRoute:
      return pageTitles.affiliationTracking;
    case routesNames.affiliationTrackingLetter:
      return pageTitles.affiliationTrackingLetter;
    case routesNames.privilegeViewerRoute:
      return pageTitles.privilegeViewer;
    default:
      return pageTitles.default;
  }
}
