import routesNames from "@constants/route-names";
import AffiliationTracking from "@pages/AffiliationTracking/AffiliationTracking";
import AffiliationTrackingLetter from "@pages/AffiliationTracking/AffiliationTrackingLetter/AffiliationTrackingLetter";
import CredTracking from "@pages/CredTracking/CredTracking";
import Dashboard from "@pages/Dashboard/Dashboard";
import PrivilegeViewer from "@pages/PrivilegeViewer/PrivilegeViewer";
import { IRoute } from "helpers/uiInterfaces/common";

const initialRoutes: Array<IRoute> = [
  {
    path: routesNames.dashboardRoute,
    breadcrumb: "Dashboard",
    element: <Dashboard />,
  },
  {
    path: routesNames.credentialingTrackingRoute,
    breadcrumb: "Credentialing Tracking",
    element: <CredTracking />,
  },
  {
    path: routesNames.affiliationTrackingRoute,
    breadcrumb: "Affiliation Tracking",
    element: <AffiliationTracking />,
  },
  {
    path: routesNames.affiliationTrackingLetter,
    breadcrumb: "Affiliation Tracking Letter",
    element: <AffiliationTrackingLetter />,
  },
  {
    path: routesNames.privilegeViewerRoute,
    breadcrumb: "Privilege Viewer",
    element: <PrivilegeViewer />,
  },
];

export default initialRoutes;
